// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-bona-js": () => import("./../../../src/pages/projects/bona.js" /* webpackChunkName: "component---src-pages-projects-bona-js" */),
  "component---src-pages-projects-hit-js": () => import("./../../../src/pages/projects/hit.js" /* webpackChunkName: "component---src-pages-projects-hit-js" */),
  "component---src-pages-projects-maily-js": () => import("./../../../src/pages/projects/maily.js" /* webpackChunkName: "component---src-pages-projects-maily-js" */)
}

